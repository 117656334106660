import React, { Component } from 'react'
import './Contact.css'

class Contact extends Component {
    render() {
        return(
            <div className='flex-container' id='contact'>
                <div>
                    <h1>Contact Me</h1>
                    <br></br>
                    <h2>Email - Personal@mansajoe.com</h2>
                </div>
            </div>
        )
    }
}

export default Contact;
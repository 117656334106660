export const MenuItems = [
    {
        title: 'Home',
        url: '#',
        cName: 'Nav-Links'
    },
    {
        title: 'About Me',
        url: '#',
        cName: 'Nav-Links'
    },
    {
        title: 'Resume',
        url: '#',
        cName: 'Nav-Links'
    },
    {
        title: 'Contact',
        url: '#',
        cName: 'Nav-Links'
    },
    {
        title: 'Sign In',
        url: '#',
        cName: 'Nav-Links-Mobile'
    }
    
]